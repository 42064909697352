import React from "react";
import { Login } from "../../components/organism/Login";
import RestrictedRoute from "../../withRestrictedRoute";

const CompanyLogin = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default RestrictedRoute(CompanyLogin);
