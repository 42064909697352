import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../../assets/logo.png";
import { Button, Form, Input } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Alert, auth, theme } from "@project/shared";

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;
const Container = styled.div`
  max-width: 314px;
`;
const StyledForm = styled(Form)`
  margin-top: 24px;
`;
const Label = styled.span`
  color: ${theme.adminTextFieldColor};
  line-height: 22px;
`;
const StyledFormItem = styled.div`
  display: flex;
  justify-content: center;
  & .ant-btn {
    min-width: 96px;
  }
`;
const Error = styled.span`
  font-size: 12px;
  color: ${theme.errorText};
`;
const TextFieldWrapper = styled.div`
  height: 94px;
`;
const Login = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t("Email Address is required."))
      .email(t("Email format is incorrect.")),
    password: yup.string().required(t("Password is required. ")),
  });
  const handleLoginFormSubmit = async () => {
    setLoading(true);
    try {
      await auth().signInWithEmailAndPassword(
        formik.values.email,
        formik.values.password
      );
    } catch (error) {
      const errorCode = error.code;

      if (
        errorCode === "auth/user-not-found" ||
        errorCode === "auth/wrong-password"
      ) {
        Alert({
          type: "error",
          message: t("Error occured"),
          description: t("Email or password doesn't match"),
        });
      } else if (errorCode === "auth/too-many-requests") {
        Alert({
          type: "warning",
          message: t("Too many request"),
          description: t(`${error?.message}`),
        });
      } else {
        Alert({
          type: "error",
          message: t("Something went wrong"),
          description: t("Please try again"),
        });
      }
    }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  });

  return (
    <MainWrapper>
      <Container>
        <img src={Logo} alt="SideBizz" />
        <StyledForm onFinish={formik.handleSubmit} layout="vertical">
          <TextFieldWrapper>
            <Form.Item label={<Label>{t("Email")}</Label>}>
              <Input
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="email"
                size="large"
                type="email"
              />
              {formik.touched.email && formik.errors.email && (
                <Error>{formik.errors.email}</Error>
              )}
            </Form.Item>
          </TextFieldWrapper>
          <TextFieldWrapper>
            <Form.Item label={<Label>{t("Password")}</Label>}>
              <Input
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="password"
                size={"large"}
                type="password"
              />
              {formik.touched.password && formik.errors.password && (
                <Error>{formik.errors.password}</Error>
              )}
            </Form.Item>
          </TextFieldWrapper>

          <StyledFormItem>
            <Button
              htmlType="submit"
              loading={loading}
              size="large"
              type="primary"
            >
              {t("Login")}
            </Button>
          </StyledFormItem>
        </StyledForm>
      </Container>
    </MainWrapper>
  );
};

export { Login };
